'use strict';

const Header =  {
    el:null,
    toggle:null,
    init: function(){
        Header.el = document.querySelector('.header');
        if(Header.el){
            Header.toggle=Header.el.querySelector('.header__toggle');
            if(Header.toggle){
                Header.toggle.addEventListener('click', () => {
                   Header.el.classList.toggle('navOpened');
                });
            }
            if (document.body.classList.contains('home')) {
                if (window.location.hash) {
                    const anchor = window.location.hash;
                    Header.scrollToAnchor(anchor);
                }
                else {
                    const timer = setTimeout(() => {
                        Header.setActiveLink();
                        clearTimeout(timer)
                    }, 500);
                }

                window.addEventListener('scroll', Header.setActiveLink);

                const anchorLinks = Header.el.querySelectorAll('.menu-item a[href^="/#"]');
                anchorLinks.forEach(link => {
                    link.addEventListener('click', function(event) {
                        event.preventDefault();
                        const anchor = this.getAttribute('href').replace(/^\/#/, '#');
                        Header.scrollToAnchor(anchor);
                        history.pushState(null, null, anchor);
                    });
                });
            }

            
        }
    },
    setActiveLink: function() {
        if (!Header.el) return;
        
        const menuItems = Header.el.querySelectorAll('.menu-item a');
        const contentSections = document.querySelectorAll('.main__flexibles > .f');
    
        let activeSection = null;
        contentSections.forEach(section => {
            const rect = section.getBoundingClientRect();
            if (rect.top <= window.innerHeight / 2) {
                activeSection = section;
            }
        });
    
        let activeSectionId = '';
        let previousSectionId = '';
        contentSections.forEach((section, index) => {
            if (section.id && section === activeSection) {
                activeSectionId = section.id;
                if (index > 0) {
                    previousSectionId = contentSections[index - 1].id;
                }
            }
        });
    
        if (!activeSectionId && contentSections.length > 0 && contentSections[0].id) {
            previousSectionId = contentSections[0].id;
        }
    
        menuItems.forEach(item => {
            if (item) { // Vérifier si l'élément est défini
                item.classList.remove('active--');
            }
        });
    
        if (activeSectionId) {
            const activeMenuItem = Header.el.querySelector(`.menu-item a[href="/#${activeSectionId}"]`);
            if (activeMenuItem) { // Vérifier si l'élément est défini
                activeMenuItem.classList.add('active--');
            }
        } else {
            const previousMenuItem = Header.el.querySelector(`.menu-item a[href="/#${previousSectionId}"]`);
            if (previousMenuItem) { // Vérifier si l'élément est défini
                previousMenuItem.classList.add('active--');
            }
        }
    },
    scrollToAnchor: function(anchor) {
        Header.el.classList.remove('navOpened');
        const offset = Header.el.offsetHeight;
        const element = document.querySelector(anchor);
        if (element) {
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({
                top: elementPosition - offset,
                behavior: 'smooth'
            });
        }
    }
};

export default Header;
