'use strict';

// core version + modules:
import Swiper, {Navigation, Scrollbar, A11y} from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y';

const SliderMulti = {
    els: null,
    init: function () {
        SliderMulti.els = document.querySelectorAll('.f-slider.multi--');
        if (SliderMulti.els && SliderMulti.els.length > 0) {
            SliderMulti.els.forEach(el => {
                SliderMulti.create(el);
            });
        }
    },
    create: function (el) {
        const slider = el.querySelector('.swiper');

        let swiper = null;


        let modules = "";
        let navigation = null;
        let scrollbar = null;

        if (slider.dataset.navigation === "fleches") {
            swiper = new Swiper(slider, {
                modules: [Navigation, A11y],
                a11y: true,
                spaceBetween: 40,
                slidesPerView: "auto",
                loop: true,
                speed: 600,
                autoHeight:false,
                centeredSlides:true,
                navigation: {
                    prevEl: ".f-slider__navigation-prev",
                    nextEl: ".f-slider__navigation-next",
                },
                breakpoints: {
                    1280: {
                      slidesPerView: 3,
                      spaceBetween: 20
                    }
                }
            });
        }
        if (slider.dataset.navigation === "scrollbar") {
            swiper = new Swiper(slider, {
                modules: [Scrollbar, A11y],
                a11y: true,
                spaceBetween: 40,
                slidesPerView: "auto",
                loop: false,
                speed: 600,
                autoHeight:false,
                scrollbar: {
                    el: ".f-slider__scrollbar",
                    hide: false,
                    draggable: true
                }
            });
        }

    },
};

export default SliderMulti;