'use strict';

const WysiwygMore = {
    els: null,
    init: function () {
        WysiwygMore.els = document.querySelectorAll('.f-wysiwyg__column');
        if (window.matchMedia("(max-width: 768px)").matches) {
            WysiwygMore.event();
        }
    },
    event: function() {
        WysiwygMore.els.forEach(elt => {
            if(elt.dataset.loadmore === "true")
                WysiwygMore.addLoadMore(elt)
        });
    },
    addLoadMore: function(elt) {
        const button = document.createElement('button');
        button.classList.add('c-button', 'multi--');
        button.innerHTML = '<span>Voir plus</span>';

        elt.appendChild(button);

        button.addEventListener('click', function() {
            elt.classList.toggle('show--');
            
            if (button.querySelector('span').textContent === 'Voir plus') {
                button.querySelector('span').textContent = 'Voir moins';
            } else {
                button.querySelector('span').textContent = 'Voir plus';
            }
        });
    }
};

export default WysiwygMore;